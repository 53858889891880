import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
// import { IoMdRocket } from "react-icons/io";
import images from "../../constants/images";

const Navbar: React.FC = () => {
  const [navToggle, setNavToggle] = useState<boolean>(false);

  const navHandler = () => {
    setNavToggle((prevData: boolean) => !prevData);
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });

      if (navToggle) {
        setNavToggle(false);
      }
    }
  };

  return (
    <nav className="navbar w-100 flex">
      <div className="container w-100">
        <div className="navbar-content flex fw-7">
          <div className="brand-and-toggler flex flex-between w-100">
            <Link to="/" className="navbar-brand fs-26">
              <img src={images.imagotipo_img_2} alt="" />
            </Link>

            <div
              typeof="button"
              className={`hamburger-menu ${
                navToggle ? "hamburger-menu-change" : ""
              }`}
              onClick={navHandler}
            >
              <div className="bar-top"></div>
              <div className="bar-middle"></div>
              <div className="bar-bottom"></div>
            </div>
          </div>

          <div
            className={`navbar-collapse ${
              navToggle ? "show-navbar-collapse" : ""
            }`}
          >
            <div className="navbar-collapse-content">
              <ul className="navbar-nav">
                <li className="text-white">
                  <Link to="/" onClick={() => scrollToSection("about")}>
                    Nosotros
                  </Link>
                </li>
                <li className="text-white">
                  <Link to="/" onClick={() => scrollToSection("portfolio")}>
                    Portafolio
                  </Link>
                </li>
                <li className="text-white">
                  <Link to="/pricing" className="link">
                    Paquetes
                  </Link>
                </li>
                <li className="text-white">
                  <Link to="/contact">Contacto</Link>
                </li>
              </ul>
              <ul className="navbar-social flex">
                <li className="text-white">
                  <Link
                    to="https://www.instagram.com/ml_dancestudio?igsh=MTA1eDFodTBqMDVxZA=="
                    className="flex flex-center"
                  >
                    <FaInstagram />
                  </Link>
                </li>
                <li className="text-white">
                  <Link
                    to="https://www.facebook.com/share/2537j897FPXGmdrw/?mibextid=LQQJ4d"
                    className="flex flex-center"
                  >
                    <FaFacebook />
                  </Link>
                </li>
                <li className="text-white">
                  <Link
                    to="https://www.tiktok.com/@mldancestudio?_t=8lhQggllzYU&_r=1"
                    className="flex flex-center"
                  >
                    <FaTiktok />
                  </Link>
                </li>
              </ul>
              {/* <div className="navbar-btns">
                <button type="button" className="btn">
                  <IoMdRocket /> <span>get started</span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
