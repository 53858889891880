import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PricingCard from "../../components/Cards/PricingCard";
import sections from "../../constants/data";
import CardAction from "../../components/Cards/CardAction";
import Footer from "../../components/Footer/Footer";
import "./Pricing.css";
import { useLocation } from "react-router-dom";

const Pricing: React.FC = () => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [showPromoPlans, setShowPromoPlans] = useState(false);

  const openingPlans = sections.pricing.slice(0, 3);
  const regularPlans = sections.pricing.slice(3, 6);
  const promoPlans = sections.pricing.slice(6, 9);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if no hash is provided
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  // const handleTogglePlans = () => {
  //   setShowPromoPlans(!showPromoPlans);
  // };

  return (
    <>
      <div className="pricing-layout section-p bg-black" id="pricing">
        <Navbar />
        <div className="container">
          <div className="pricing-content">
            <div className="section-t text-center">
              <h3>Planes / Paquetes</h3>
              <p className="text">
                En ML Dance Studio, creemos en hacer el baile accesible para
                todos, sin importar tu nivel de experiencia o presupuesto. Por
                eso, hemos diseñado una variedad de opciones de precios para
                adaptarnos a tus necesidades individuales. Ya sea que estés
                buscando sumergirte en una experiencia completa de aprendizaje o
                simplemente quieras explorar un nuevo estilo de baile, nuestros
                paquetes de precios están diseñados para ofrecerte flexibilidad
                y valor. Descubre nuestras opciones de paquetes a continuación y
                comienza tu viaje en el mundo del baile con nosotros.
              </p>
            </div>
            <div className="section-plan">
              <h3 className="section-t text-title text-center">
                Paquetes 8, 12, 16
              </h3>
              <div className="item-list grid text-white text-center">
                {openingPlans.map((pricing, index) => (
                  <React.Fragment key={index}>
                    {windowWidth >= 432 ? (
                      <PricingCard
                        key={index}
                        id={pricing.id}
                        name={pricing.name}
                        price={pricing.price}
                        time={pricing.time}
                        image={pricing.image}
                        content={pricing.content}
                      />
                    ) : (
                      <CardAction
                        key={index}
                        id={pricing.id}
                        name={pricing.name}
                        price={pricing.price}
                        time={pricing.time}
                        image={pricing.image}
                        content={pricing.content}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <br />
            <br />
            {/* <div className="toggle-switch-container">
              <div className="toggle-switch">
                <span className="text">
                  Mostrar plan {showPromoPlans ? "regular" : "promo 10%"}
                </span>
                <input
                  type="checkbox"
                  id="toggleSwitch"
                  checked={showPromoPlans}
                  onChange={handleTogglePlans}
                />
                <label htmlFor="toggleSwitch"></label>
              </div>
            </div> */}
            <div className="item-list grid text-white text-center">
              {false
                ? promoPlans.map((pricing, index) => (
                    <React.Fragment key={index}>
                      {windowWidth >= 432 ? (
                        <PricingCard
                          key={index}
                          id={pricing.id}
                          name={pricing.name}
                          price={pricing.price}
                          time={pricing.time}
                          image={pricing.image}
                          content={pricing.content}
                        />
                      ) : (
                        <CardAction
                          key={index}
                          id={pricing.id}
                          name={pricing.name}
                          price={pricing.price}
                          time={pricing.time}
                          image={pricing.image}
                          content={pricing.content}
                        />
                      )}
                    </React.Fragment>
                  ))
                : regularPlans.map((pricing, index) => (
                    <React.Fragment key={index}>
                      {windowWidth >= 432 ? (
                        <PricingCard
                          key={index}
                          id={pricing.id}
                          name={pricing.name}
                          price={pricing.price}
                          time={pricing.time}
                          image={pricing.image}
                          content={pricing.content}
                        />
                      ) : (
                        <CardAction
                          key={index}
                          id={pricing.id}
                          name={pricing.name}
                          price={pricing.price}
                          time={pricing.time}
                          image={pricing.image}
                          content={pricing.content}
                        />
                      )}
                    </React.Fragment>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
